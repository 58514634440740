import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroImage from "../images/mini-hd-walk-behind-header-500h.jpg"
import styled from "styled-components"
import SeeMoreButton from "../components/controls/SeeMoreButton"
import ProductLayout from "../components/ProductLayout"
import { Link } from "gatsby"

import MicroMagImage from "../components/individualProductPage/images/tr-sweeper-1.jpg"
import MicroMiniImage from "../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import MicroHDImage from "../components/individualProductPage/images/micro-hd-25c-floor-360s-view-1.jpg"
import MiniHDImage from "../images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import MagHDImage from "../components/individualProductPage/images/mag-hd-29c-360-view-1.jpg"

const WbfsPageStyles = styled.div`
  .hero-container {
    position: relative;
    width: 100%;
    height: 30vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .heading {
    position: absolute;
    top: 50%;
    margin-top: -170px;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.7); */
    padding: 3rem 0;
    font-size: 2em;
    font-weight: bold;
    color: white;

    text-align: center;
    font-family: "Titillium Web", sans-serif;
    @media (max-width: 840px) {
      margin-top: -250px;
    }

    h1 {
      margin: 0;
    }
  }

  .product-layout {
    /* margin-top: 150px; */
    padding: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem;
    justify-items: center;

    @media (max-width: 1420px) {
      width: 95%;
      /* margin: 0; */
      grid-gap: 0;
    }
    @media (max-width: 900px) {
      padding: 1rem;
      grid-gap: 0;
    }
    @media (max-width: 700px) {
      padding: 0.5rem;
      grid-gap: 0;
      grid-template-columns: 100%;
    }
  }

  button {
    background-color: orange;
    text-align: center;
    height: 40px;
    padding: 0.5rem;
    border: none;
    color: white;
    margin: 3rem 0;
    /* margin: 4rem 0 0 12rem; */
    /* border-radius: 5px; */
  }
`

const RideOnSweeperPage = () => {
  return (
    <>
      <SEO title="Walk Behind Floor Scrubbers" />
      <Layout>
        <WbfsPageStyles>
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "30px",
            }}
          ></div>
          <div className="hero-container">
            <img src={HeroImage} alt="" />
          </div>
          {/* <div className="heading">
            <h1>Walk Behind Floor Scrubbers</h1>
            <p style={{ margin: "0", fontSize: ".6em", padding: ".5rem" }}>
              Providing outstanding quality and durability to last years.
            </p>
            <SeeMoreButton
              title="See Machines"
              goTo="#walk-behind-floor-scrubber-products"
            />
          </div> */}
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "30px",
            }}
          ></div>

          <div
            className="product-layout"
            id="walk-behind-floor-scrubber-products"
          >
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/ride-on-floor-sweepers/tr"
            >
              <ProductLayout
                image={MicroMagImage}
                title="TR Ride On Sweeper"
                linkName="Download TR Brochure"
                link="https://www.dropbox.com/sh/qxwutzvg3snsvd7/AAAbRfBZ3Yijhf1noA_nAobJa/FC-34-TR-BROCHURE.pdf?dl=0"
                content="Durability and maneuverability are built into this small, battery powered, industrial ride-on sweeper.  Equipped with its high volume, low pressure suction system, massive bag house style filter element, dual side brooms and steel hopper, this battery powered sweeper is up to the task at hand. With an assortment of brooms available this sweeper is suitable for many debris and types of surfaces."
                navigate="/ride-on-floor-sweepers/tr"
                subTitle="Compact Battery Ride on Sweeper"
              />
            </Link>
            {/* <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/walk-behind-floor-scrubbers/micromini"
            >
              <ProductLayout
                image={MicroMiniImage}
                title="MicroMini"
                link="Download MicroMini Brochure"
                content="MicroMini Floor Scrubber Dryer is known for its simple design and durable construction, offering unmatched value for the customer. It comes equipped with a Traction... "
              />
            </Link> */}
            {/* <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/walk-behind-floor-scrubbers/micro-hd"
            >
              <ProductLayout
                image={MicroHDImage}
                title="Micro-HD"
                link="Download Micro-HD Brochure"
                content="Micro-HD Floor Scrubber Dryer is known for its simple design and durable construction, offering unmatched value for the customer. Comes equipped with a Traction... "
              />
            </Link>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/walk-behind-floor-scrubbers/mini-hd"
            >
              <ProductLayout
                image={MiniHDImage}
                title="Mini-HD"
                link="Download Mini-HD Brochure"
                content="Mini-HD Floor Scrubber is known for its simple design and durable construction, offering unmatched value for the customer. The Mini-HD comes equipped... "
              />
            </Link>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/walk-behind-floor-scrubbers/mag-hd"
            >
              <ProductLayout
                image={MagHDImage}
                title="Mag-HD"
                link="Download Mag-HD Brochure"
                content="Mag-HD Floor Scrubber Dryer is known for its simple design and durable construction, offering unmatched value for the customer. Comes equipped with a Traction... "
              />
            </Link> */}
            {/* <button>Download FactoryCat's Full Line Brochure</button> */}
            {/* <ProductLayout />
            <ProductLayout />
            <ProductLayout />
            <ProductLayout />
            <ProductLayout /> */}
          </div>
        </WbfsPageStyles>
      </Layout>
    </>
  )
}

export default RideOnSweeperPage
